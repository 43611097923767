import { EntitySwitch } from '@backstage/plugin-catalog';
import {
  EntityGithubActionsContent,
  isGithubActionsAvailable,
} from '@backstage-community/plugin-github-actions';
import { EmptyState } from '@backstage/core-components';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  Grid,
  Typography,
} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import React from 'react';
import {
  EntityArgoCDContent,
  EntityArgoCDOverviewCard,
  isArgocdAvailable,
} from '../../../plugins/backstage-plugin-argo-cd';

const cicdContent = (
  // This is an example of how you can implement your company's logic in entity page.
  // You can for example enforce that all components of type 'service' should use GitHubActions
  <div>
    <Accordion>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="gha-content"
        id="gha-header"
      >
        <Typography>Github Actions</Typography>
      </AccordionSummary>
      <AccordionDetails>
        <EntitySwitch>
          <EntitySwitch.Case if={isGithubActionsAvailable}>
            <EntityGithubActionsContent />
          </EntitySwitch.Case>

          <EntitySwitch.Case>
            <EmptyState
              title="No CI/CD available for this entity"
              missing="info"
              description="You need to add an annotation to your component if you want to enable CI/CD for it. You can read more about annotations in Backstage by clicking the button below."
              action={
                <Button
                  variant="contained"
                  color="primary"
                  href="https://backstage.io/docs/features/software-catalog/well-known-annotations"
                >
                  Read more
                </Button>
              }
            />
          </EntitySwitch.Case>
        </EntitySwitch>
      </AccordionDetails>
    </Accordion>
    <Accordion>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="argocd-content"
        id="argocd-header"
      >
        <Typography>Argo CD</Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Grid container spacing={3} alignItems="stretch">
          <EntitySwitch>
            <EntitySwitch.Case if={e => Boolean(isArgocdAvailable(e))}>
              <Grid item sm={12}>
                <EntityArgoCDOverviewCard />
              </Grid>
              <Grid item sm={12}>
                <EntityArgoCDContent />
              </Grid>
            </EntitySwitch.Case>
          </EntitySwitch>
        </Grid>
      </AccordionDetails>
    </Accordion>
  </div>
);
export default cicdContent;
