import { Grid } from '@material-ui/core';
import entityWarningContent from './EntityWarning';
import {
  EntityAboutCard,
  EntityHasSubcomponentsCard,
  EntityLinksCard,
  EntitySwitch,
} from '@backstage/plugin-catalog';
import { EntityCatalogGraphCard } from '@backstage/plugin-catalog-graph';
import {
  EntityArgoCDOverviewCard,
  isArgocdAvailable,
} from '../../../plugins/backstage-plugin-argo-cd';
import React from 'react';
// import {GithubIssuesCard} from "@backstage/plugin-github-issues";
import { EntityTechInsightsScorecardCard } from '@backstage-community/plugin-tech-insights';

const overviewContent = (
  <Grid container spacing={3} alignItems="stretch">
    {entityWarningContent}
    <Grid item md={6}>
      <EntityAboutCard variant="gridItem" />
    </Grid>
    <Grid item md={6} xs={12}>
      <EntityTechInsightsScorecardCard
        title="Scorecard"
        description="Tech Insights Scorecard"
      />
    </Grid>
    <Grid item md={6} xs={12}>
      <EntityCatalogGraphCard variant="gridItem" height={400} />
    </Grid>
    {/*<Grid item md={6} xs={12}>*/}
    {/*	<GithubIssuesCard/>*/}
    {/*</Grid>*/}
    <Grid item sm={12} md={6}>
      <EntitySwitch>
        <EntitySwitch.Case if={e => Boolean(isArgocdAvailable(e))}>
          <EntityArgoCDOverviewCard />
        </EntitySwitch.Case>
      </EntitySwitch>
    </Grid>

    <Grid item md={6} xs={12}>
      <EntityLinksCard />
    </Grid>
    <Grid item md={6} xs={12}>
      <EntityHasSubcomponentsCard variant="gridItem" />
    </Grid>

    {/*<Grid item md={6} xs={12}>*/}
    {/*  <EntityTechInsightsScorecardCard*/}
    {/*    title="Tech Insights Scorecard"*/}
    {/*  />*/}
    {/*</Grid>*/}
  </Grid>
);

export default overviewContent;
