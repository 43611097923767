import {EntitySwitch, isComponentType} from "@backstage/plugin-catalog";
import React from "react";

import serviceEntityPage from "./ServiceEntity";
import websiteEntityPage from "./WebsiteEntity";
import defaultEntityPage from "./DefaultEntity";

const componentPage = (
	<EntitySwitch>
		<EntitySwitch.Case if={isComponentType('service')}>
			{serviceEntityPage}
		</EntitySwitch.Case>

		<EntitySwitch.Case if={isComponentType('website')}>
			{websiteEntityPage}
		</EntitySwitch.Case>

		<EntitySwitch.Case>{defaultEntityPage}</EntitySwitch.Case>
	</EntitySwitch>
);

export default componentPage;
