import {
	EntityAboutCard,
	EntityHasComponentsCard,
	EntityHasResourcesCard,
	EntityLayout,
	EntityLinksCard
} from "@backstage/plugin-catalog";
import {Grid} from "@material-ui/core";
import {Direction, EntityCatalogGraphCard} from "@backstage/plugin-catalog-graph";
import {EntityHasApisCard} from "@backstage/plugin-api-docs";
import {
	RELATION_API_CONSUMED_BY,
	RELATION_API_PROVIDED_BY, RELATION_CONSUMES_API, RELATION_DEPENDENCY_OF, RELATION_DEPENDS_ON,
	RELATION_HAS_PART,
	RELATION_PART_OF, RELATION_PROVIDES_API
} from "@backstage/catalog-model";
import React from "react";

import entityWarningContent from "../content/EntityWarning";

const systemPage = (
	<EntityLayout>
		<EntityLayout.Route path="/" title="Overview">
			<Grid container spacing={3} alignItems="stretch">
				{entityWarningContent}
				<Grid item md={6}>
					<EntityAboutCard variant="gridItem"/>
				</Grid>
				<Grid item md={6} xs={12}>
					<EntityCatalogGraphCard variant="gridItem" height={400}/>
				</Grid>
				<Grid item md={4} xs={12}>
					<EntityLinksCard/>
				</Grid>
				<Grid item md={8}>
					<EntityHasComponentsCard variant="gridItem"/>
				</Grid>
				<Grid item md={6}>
					<EntityHasApisCard variant="gridItem"/>
				</Grid>
				<Grid item md={6}>
					<EntityHasResourcesCard variant="gridItem"/>
				</Grid>
			</Grid>
		</EntityLayout.Route>
		<EntityLayout.Route path="/diagram" title="Diagram">
			<EntityCatalogGraphCard
				variant="gridItem"
				direction={Direction.TOP_BOTTOM}
				title="System Diagram"
				height={700}
				relations={[
					RELATION_PART_OF,
					RELATION_HAS_PART,
					RELATION_API_CONSUMED_BY,
					RELATION_API_PROVIDED_BY,
					RELATION_CONSUMES_API,
					RELATION_PROVIDES_API,
					RELATION_DEPENDENCY_OF,
					RELATION_DEPENDS_ON,
				]}
				unidirectional={false}
			/>
		</EntityLayout.Route>
	</EntityLayout>
);

export default systemPage;
