import React from 'react';

import {
	EntitySwitch,
	isKind,
} from '@backstage/plugin-catalog';


import apiPage from "./pages/API";
import componentPage from "./pages/Component";
import defaultEntityPage from "./pages/DefaultEntity";
import domainPage from "./pages/Domain";
import groupPage from "./pages/Group";
import systemPage from "./pages/System";
import userPage from "./pages/User";



export const entityPage = (
	<EntitySwitch>
		<EntitySwitch.Case if={isKind('component')} children={componentPage}/>
		<EntitySwitch.Case if={isKind('api')} children={apiPage}/>
		<EntitySwitch.Case if={isKind('group')} children={groupPage}/>
		<EntitySwitch.Case if={isKind('user')} children={userPage}/>
		<EntitySwitch.Case if={isKind('system')} children={systemPage}/>
		<EntitySwitch.Case if={isKind('domain')} children={domainPage}/>

		<EntitySwitch.Case>{defaultEntityPage}</EntitySwitch.Case>
	</EntitySwitch>
);
