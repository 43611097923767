import {
  CatalogKindExploreContent,
  DomainExplorerContent,
  ExploreLayout,
  GroupsExplorerContent,
  ToolExplorerContent,
} from '@backstage-community/plugin-explore';
import React from 'react';

export const ExplorePage = () => {
  return (
    <ExploreLayout
      title="Explore the Pepper ecosystem"
      subtitle="Browse our ecosystem"
    >
      <ExploreLayout.Route path="groups" title="Groups">
        <GroupsExplorerContent />
      </ExploreLayout.Route>

      <ExploreLayout.Route path="domains" title="Domains">
        <DomainExplorerContent />
      </ExploreLayout.Route>
      <ExploreLayout.Route path="systems" title="Systems">
        <CatalogKindExploreContent kind="system" />
      </ExploreLayout.Route>
      <ExploreLayout.Route path="tools" title="Tools">
        <ToolExplorerContent />
      </ExploreLayout.Route>
    </ExploreLayout>
  );
};

export const explorePage = <ExplorePage />;
