import { EntityLayout } from '@backstage/plugin-catalog';
import techdocsContent from '../content/Techdocs';
import React from 'react';

import overviewContent from '../content/Overview';
import { Grid } from '@material-ui/core';
import { EntityTodoContent } from '@backstage-community/plugin-todo';
import { EntityGithubInsightsContent } from '@roadiehq/backstage-plugin-github-insights';
import {
  EntityAdrContent,
  isAdrAvailable,
} from '@backstage-community/plugin-adr';

const defaultEntityPage = (
  <EntityLayout>
    <EntityLayout.Route path="/" title="Overview">
      {overviewContent}
    </EntityLayout.Route>

    <EntityLayout.Route path="/docs" title="Docs">
      {techdocsContent}
    </EntityLayout.Route>

    <EntityLayout.Route path="/tech-insights" title="Insights">
      <Grid container spacing={3}>
        <Grid item sm={12}>
          <EntityGithubInsightsContent />
        </Grid>
        <Grid item sm={12}>
          <EntityTodoContent />
        </Grid>
      </Grid>
    </EntityLayout.Route>
    <EntityLayout.Route if={isAdrAvailable} path="/adrs" title="ADRs">
      <EntityAdrContent />
    </EntityLayout.Route>
  </EntityLayout>
);

export default defaultEntityPage;
