import {
  EntityDependsOnComponentsCard,
  EntityDependsOnResourcesCard,
  EntityLayout,
} from '@backstage/plugin-catalog';
import cicdContent from '../content/CICDContent';
import { Grid } from '@material-ui/core';
import {
  EntityConsumedApisCard,
  EntityProvidedApisCard,
} from '@backstage/plugin-api-docs';
import techdocsContent from '../content/Techdocs';
import { EntityKubernetesContent } from '@backstage/plugin-kubernetes';

import React from 'react';

import overviewContent from '../content/Overview';
import { EntityTodoContent } from '@backstage-community/plugin-todo';
import { EntityGithubInsightsContent } from '@roadiehq/backstage-plugin-github-insights';
import {
  EntityAdrContent,
  isAdrAvailable,
} from '@backstage-community/plugin-adr';

const serviceEntityPage = (
  <EntityLayout>
    <EntityLayout.Route path="/" title="Overview">
      {overviewContent}
    </EntityLayout.Route>

    <EntityLayout.Route path="/docs" title="Docs">
      {techdocsContent}
    </EntityLayout.Route>

    <EntityLayout.Route path="/ci-cd" title="CI/CD">
      {cicdContent}
    </EntityLayout.Route>

    <EntityLayout.Route path="/api" title="API">
      <Grid container spacing={3} alignItems="stretch">
        <Grid item md={6}>
          <EntityProvidedApisCard />
        </Grid>
        <Grid item md={6}>
          <EntityConsumedApisCard />
        </Grid>
      </Grid>
    </EntityLayout.Route>

    <EntityLayout.Route path="/dependencies" title="Dependencies">
      <Grid container spacing={3} alignItems="stretch">
        <Grid item md={6}>
          <EntityDependsOnComponentsCard variant="gridItem" />
        </Grid>
        <Grid item md={6}>
          <EntityDependsOnResourcesCard variant="gridItem" />
        </Grid>
      </Grid>
    </EntityLayout.Route>

    <EntityLayout.Route path="/tech-insights" title="Insights">
      <Grid container spacing={3}>
        <Grid item sm={12}>
          <EntityGithubInsightsContent />
        </Grid>
        <Grid item sm={12}>
          <EntityTodoContent />
        </Grid>
      </Grid>
    </EntityLayout.Route>

    <EntityLayout.Route path="/kubernetes" title="Kubernetes">
      <EntityKubernetesContent refreshIntervalMs={30000} />
    </EntityLayout.Route>
    <EntityLayout.Route if={isAdrAvailable} path="/adrs" title="ADRs">
      <EntityAdrContent />
    </EntityLayout.Route>
    {/* moved to ci/cd page */}
    {/*	<EntityLayout.Route path="/argocd" title="ArgoCD">*/}
    {/*		<Grid container spacing={3} alignItems="stretch">*/}
    {/*			<EntitySwitch>*/}
    {/*				<EntitySwitch.Case if={e => Boolean(isArgocdAvailable(e))}>*/}
    {/*					<Grid item sm={12}>*/}
    {/*						<EntityArgoCDOverviewCard/>*/}
    {/*					</Grid>*/}
    {/*				</EntitySwitch.Case>*/}
    {/*			</EntitySwitch>*/}
    {/*			<Grid item sm={12}>*/}
    {/*				<EntityArgoCDContent/>*/}
    {/*			</Grid>*/}
    {/*		</Grid>*/}
    {/*	</EntityLayout.Route>*/}
  </EntityLayout>
);

export default serviceEntityPage;
