import { EntityLayout } from '@backstage/plugin-catalog';
import { Grid } from '@material-ui/core';
import {
  EntityGroupProfileCard,
  EntityMembersListCard,
  EntityOwnershipCard,
} from '@backstage/plugin-org';
import { EntityTeamPullRequestsCard } from '@backstage-community/plugin-github-pull-requests-board';
import React from 'react';

import entityWarningContent from '../content/EntityWarning';

const groupPage = (
  <EntityLayout>
    <EntityLayout.Route path="/" title="Overview">
      <Grid container spacing={3}>
        {entityWarningContent}
        <Grid item xs={12} md={6}>
          <EntityGroupProfileCard variant="gridItem" />
        </Grid>
        <Grid item xs={12} md={6}>
          <EntityOwnershipCard variant="gridItem" />
        </Grid>
        <Grid item xs={12}>
          <EntityMembersListCard />
        </Grid>
        <Grid item xs={12}>
          <EntityTeamPullRequestsCard />
        </Grid>
      </Grid>
    </EntityLayout.Route>
  </EntityLayout>
);

export default groupPage;
