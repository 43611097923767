
import {
  HomePageCompanyLogo,
  HomePageStarredEntities,
} from '@backstage/plugin-home';
import { Content, Page, InfoCard } from '@backstage/core-components';

import { HomePageSearchBar, } from '@backstage/plugin-search';
import {
  SearchContextProvider,
} from '@backstage/plugin-search-react';
import { Grid, makeStyles } from '@material-ui/core';
import React from 'react';
import LogoFull from "../Root/LogoFull";
import { EntityListProvider } from '@backstage/plugin-catalog-react';


const useStyles = makeStyles(theme => ({
  searchBar: {
    display: 'flex',
    maxWidth: '60vw',
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[1],
    padding: '8px 0',
    borderRadius: '50px',
    margin: 'auto',
  },
}));

const useLogoStyles = makeStyles(theme => ({
  container: {

  },
  svg: {
    margin: theme.spacing(5, 0),
    minWidth: 200,
    minHeight: 50,
  },
  path: {
    fill: '#7df3e1',
  },
}));

const DefaultTemplate = () => {
  const classes = useStyles();
  const { svg } = useLogoStyles();

  return (
    <SearchContextProvider>
      <EntityListProvider>
        <Page themeId="home">
          <Content>
            <Grid container justifyContent="center" spacing={6}>
              <HomePageCompanyLogo
                className={svg}
                logo={<LogoFull />}
              />
              <Grid container item xs={12} alignItems="center" direction="row">
                <HomePageSearchBar
                  classes={{ root: classes.searchBar }}
                  placeholder="Search"
                />
              </Grid>
              <Grid container item xs={12}>
                <Grid item xs={12} md={6}>
                  <HomePageStarredEntities />
                </Grid>
                {/*<Grid item xs={12} md={6}>*/}
                {/*	<HomePageToolkit*/}
                {/*		tools={Array(8).fill({*/}
                {/*			url: '#',*/}
                {/*			label: 'link',*/}
                {/*			icon: <LogoIcon />,*/}
                {/*		})}*/}
                {/*	/>*/}
                {/*</Grid>*/}
                <Grid item xs={12} md={6}>
                  <InfoCard title="Welcome to Backstage!">
                    <h1>Our internal Pepper Developer Platform</h1>
                    <p>If you're new around here, check out this helpful intro video:</p>
                    <iframe width="100%" height="315" src="https://www.youtube.com/embed/85TQEpNCaU0"
                      title="YouTube video player" frameBorder="0"
                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                      allowFullScreen></iframe>

                  </InfoCard>
                </Grid>
                {/*<Grid item xs={12} md={6}>*/}
                {/*  <CatalogTable />*/}
                {/*  /!* <EntityLayout> *!/*/}
                {/*  /!* <EntityUserProfileCard variant="gridItem" /> *!/*/}
                {/*  /!* </EntityLayout> *!/*/}
                {/*</Grid>*/}
                {/*<Grid item xs={12} md={6}>*/}
                {/*  /!* <EntityLayout> *!/*/}
                {/*  /!* <EntityOwnershipCard variant="gridItem" /> *!/*/}
                {/*  /!* </EntityLayout> *!/*/}
                {/*</Grid>*/}

              </Grid>
            </Grid>
          </Content>
        </Page>
      </EntityListProvider>
    </SearchContextProvider>
  );
};

export default DefaultTemplate;
